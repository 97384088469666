<template>
  <div class="work-page wsw">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/WSW_1.jpg'"/>
    
  		<div class="meta">
  			<p class="client">NIKE</p>
  			<a class="project">WSWFC KIT LAUNCH</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>We are Wanderers of Western Sydney.</h1>
  			</div>
  			<div class="col-2">
  				<p>Of the club, for the club. Infused with the spirit of the Western Sydney Wanderers. The 2018 Nike kit launch featuring a brand new 3rd kit.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <div class="two-col">
      <div class="col" style="width: 40%; margin-right: -40px; z-index: 2;">
        <img src="/static/images/WSW_2.jpg"/>
      </div>
      <div class="col" style="width: 65%">
        <img src="/static/images/WSW_3.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div class="two-col" style="display: flex; align-items: center; justify-content: space-between;">
      <div class="col" style="width: 49%;">
        <img src="/static/images/WSW_4.jpg"/>
      </div>
      <div class="col" style="width: 49%">
        <img src="/static/images/WSW_5.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/WSW_6.png"/>

    <div class="spacer-lg"></div>

    <img src="/static/images/WSW_7.jpg"/>

    <div class="spacer-lg"></div>

    <div class="two-col" style="display: flex; align-items: center; justify-content: flex-start;">
      <div class="col" style="width: 40%; margin-right: 5%">
         <!-- Mobile video mockup -->
        <div class="mobile-mockup-wrapper">
          <div class="mobile-mockup-container" style="width: 100%; margin-bottom: -60%">
            <video 
              width="100%" 
              height="auto" 
              src="/static/videos/WSW_VIDEO.mp4"  
              frameborder="0" 
              playsinline
              loop
              autoplay
              muted></video>
          </div>
        </div>
      </div>
      <div class="col" style="width: 47%">
        <img src="/static/images/WSW_8.png" style="margin-top: -65%"/>
      </div>
    </div>

    <div class="spacer-lg" style="margin-bottom: 25%;"></div>

    <img src="/static/images/WSW_9.jpg"/>

    <div class="spacer-lg"></div>

    <img src="/static/images/WSW_10.png"/>

    <div class="footer">
      <div class="prev">
        <router-link to="/super-netball">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/nike-women">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>
    
  </div>
</template>


<script>
export default {
  name: 'wsw',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.wsw {

/*  .copy container {
    .col-1 {
      width: 55%;
    }
  }
*/
  .no-negative-top-mobile {
    @media(max-width: 680px) {
      
      .col:first-child {
         width: 39% !important;
         z-index: 2 !important;
        img {
          width: 60% !important;
          margin-right: -10% !important;
          margin-top: 10% !important;
        }
      }

      .col:last-child {
        width: 59% !important;
      } 
    }
  }

}

</style>
